import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

export default function Sidebar({ data }) {
  return (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(
            sort: { fields: frontmatter___item_order, order: ASC }
            filter: { frontmatter: { type: { eq: "docs" } } }
          ) {
            group(field: frontmatter___category_order) {
              edges {
                node {
                  frontmatter {
                    title
                    nav_title
                    path
                    category
                    category_permalink
                    category_order
                    item_order
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            {data.allMarkdownRemark.group.map((groupNode, index) => {
              console.log("group : ", groupNode)

              return (
                <div
                  key={index}
                  className={`item-list ${
                    groupNode.edges.length > 1 ? "hasSubMenu" : ""
                  }`}
                >
                  <div className="list--title">
                    <Link
                      to={
                        groupNode.edges[0].node.frontmatter.category_permalink
                      }
                    >
                      {groupNode.edges[0].node.frontmatter.category}
                    </Link>
                  </div>
                  {groupNode.edges.length > 1 && (
                    <ul>
                      {groupNode.edges.map(
                        (element, index) =>
                          element.node.frontmatter.nav_title !== "index" && (
                            <li key={index}>
                              <Link
                                to={element.node.frontmatter.path}
                                activeClassName="active"
                              >
                                {element.node.frontmatter.nav_title}
                              </Link>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </div>
              )
            })}
          </>
        )
      }}
    />
  )
}
