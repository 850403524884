import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/docs-layout"
import SEO from "../components/seo"
import Sidebar from "./docSidebar"

// load css
import "./../styles/docs-interface.css"

import OpenMobileMenuIcon from "./../assets/icons/svg/menu_white.svg"
import CloseMobileMenuIcon from "./../assets/icons/svg/menu_close.svg"

export default function DocsTemplate({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  const [isOpenMenu, setOpenMenu] = useState(false)

  const handleChange__openMobileSideBar = () => {
    setOpenMenu(true)
  }

  const handleChange__closeMobileSideBar = () => {
    setOpenMenu(false)
  }

  return (
    <Layout>
    <SEO
      title={frontmatter.title}
      description={frontmatter.description}
      pathname={frontmatter.path}
      metaTitle={frontmatter.meta_title}
      image={{
        src: "/general-image-og--odown-docs.png",
        width: 1000,
        height: 400,
      }}
    />
    {isOpenMenu === false && (
      <div className={`sidebar-open`} onClick={handleChange__openMobileSideBar}>
        <OpenMobileMenuIcon />
      </div>
    )}
    <div className="docs-layout">
      <div className={`docs-layout__sidebar ${isOpenMenu === true ? "active" : ""}`}>
        <Sidebar />
        {isOpenMenu === true && (
                  <div className={`sidebar-close`} onClick={handleChange__closeMobileSideBar}>
                    <CloseMobileMenuIcon />
                  </div>
                )}      </div>
      <div className="docs-layout__content">
        <div className="layout-content__wrapper">
          <div className="article-breadcrumb">
            <ul>
              <li className="home">
                <Link to={`/docs`}>Docs Home</Link>
              </li>
              {frontmatter.category !== "Docs Home" && (
                <li>
                    <Link to={frontmatter.category_permalink}>
                      {frontmatter.category}
                    </Link>
                </li>
              )}
              {frontmatter.nav_title !== "index" && (
                <li>
                  <Link to={frontmatter.path}>{frontmatter.nav_title}</Link>
                </li>
              )}
            </ul>
          </div>
          <div className="article-body">
            <h1>{frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        category
        nav_title
        category_permalink
        description
        meta_title
      }
    }
  }
`
