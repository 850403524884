/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

const Wrapper = props => {
  return <div className="main-wrapper">{props.children}</div>
}

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  // 	query SiteTitleQuery {
  // 		site {
  // 			siteMetadata {
  // 				title
  // 			}
  // 		}
  // 	}
  // `)

  return (
    <>
      <Header size="full" position="fixed" />
      <Wrapper>
        <main>{children}</main>
      </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
